 export var portalModule= angular.module('portal', [
        'dev-stack-frontend',
        'dev-stack-ui-bootstrap',
        'portal.services',
        'portal.controllers',
        'portal.filters',
        'portal.directives',
		'dialogs.main'
    ]).config(function (devStackServiceProvider,devStackTranslationServiceProvider, devStackMessagingServiceProvider,devStackNotificationServiceProvider) {
      devStackTranslationServiceProvider.addTranslationBundle('portal.admin');
      devStackTranslationServiceProvider.addTranslationBundle('application.portal');
      if (devStackServiceProvider.getRestRemoteConfig()['portalBackup'] == 'true') {
          devStackServiceProvider.directoryLinkDisplayed(false);
      } else {
          devStackServiceProvider.topBarAdminDisplayed(true);
          //devStackServiceProvider.activateTopbarNotification();
      }
    });
