'use strict';

/**
 * @ngdoc function
 * @name delfingenApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the delfingenApp
 */
import { portalControllersModule } from "../controllers/portal-controllers-module";
import { App } from "../../../scripts/assets/app";
portalControllersModule.controller('MainCtrl', function($scope,$location,$window,devStackService,securityService,devStackNotificationService) {

       var portalAdminRole;

       App.init();
       $scope.routeToDirectory = function() {
       	  $location.path('/directory');
       };


       $scope.routeToMail = function() {
       	  $window.open('https://mail.google.com');
       };

       $scope.routeToITCard = function() {
       	  $('#itCardModal').modal({show:true});
       };
       

       $scope.sendMessage = function(){
              devStackNotificationService.sendTopBarNotification('ldidier','test notification');
       }

});