'use strict';

/**
 * @ngdoc function
 * @name delfingenApp.controller:CarouselCtrl
 * @description
 * # CarouselCtrl
 * Controller of the delfingenApp
 */

import { portalControllersModule } from "../controllers/portal-controllers-module";
import { TypeUtils } from "../../../../node_modules/dev-stack-ui-bootstrap/src/classes/utils/TypeUtils";
import { SimpleCriterion } from "node_modules/dev-stack-ui-bootstrap/src/classes/criteria/SimpleCriterion"
import { LogicalCriterion } from "node_modules/dev-stack-ui-bootstrap/src/classes/criteria/LogicalCriterion";
portalControllersModule.controller('AdminCtrl', function($scope,devStackService,$resource,$filter,$timeout,securityService,
                                                         directoryService,portalService,FileUploader,devStackTranslationService,$q,$translate,$log,devStackGenericService,criteriaService) {

    $scope.restAppBackendUrl =  devStackService.restAppBackendUrl();

	// set date to end of day to be able to show tags when slide is activ only one day.
	const date = new Date();
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	
	//control date now
	$scope.tempDate = date;
	$scope.tempDateMillis = $scope.tempDate.getTime();
    $scope.activeSlide= {};
    $scope.activeSlide.sitesFull= [];
	$scope.activeSlide.sitesSelected= [];
    $scope.activeSlide.locales= ['en'];
    $scope.activeSlide.translations= [];

    $scope.saveBtnIsDisabled =false;
    $scope.publishBtnIsDisabled =false;

    $scope.uploader = new FileUploader();
    $scope.uploadImage = function(currentSlide) {
        // upload the image
        $scope.uploader.isHTML5 = true;
        $scope.uploader.removeAfterUpload = true;
        if (TypeUtils.isEmpty($scope.uploader.queue))
            return;

        $scope.uploader.queue[0].alias = 'picture';
        $scope.uploader.queue[0].withCredentials = true;
        $scope.uploader.queue[0].url = devStackService.restAppBackendUrl() + '/portal/slides/' + currentSlide.id + '/picture/';
        $scope.uploader.uploadAll();
        $scope.uploader.onSuccessItem = function(fileItem, response, status, headers) {
            $scope.uploader.clearQueue();

            if (status === 200) {
                $scope.refreshSlide().then(function(slides) {
                    $translate.refresh();
                    $scope.slides = slides;
                    var s = _.findWhere(slides,{id:currentSlide.id});
                    $scope.setActiveSlide(s);

                });
            } else {
                //TODO: message d'erreur personnalisé ???
                $scope.error = true;
            }
        };
		goToEdit();
    }


    var getSitesIds = function(slide){
        var sites = [];
        angular.forEach(slide.sitesFull,function(siteFull){
            sites.push(siteFull.id);
        })
        return sites;
    }
	
    var setLocales = function(){
        $scope.activeSlide.locales = _.pluck($scope.activeSlide.sitesFull,'mainLocale');
        $scope.activeSlide.locales = _.uniq($scope.activeSlide.locales);
    }


	var goToEdit = function() {
		var url = location.href;
    	location.href = "#focusEdit";
   		history.replaceState(null,null,url);
	}

    /* Merge of onRemove and OnSelect functions: 
	without the titles in slides they are the same functions. */
    $scope.onModification = function(item){
        $scope.activeSlide.sites = getSitesIds($scope.activeSlide);		
    }

    $scope.setActiveSlide = function (slide) {	
				
        $scope.activeSlide = slide;
        console.log('Math.random() : ' + Math.random())
		$scope.activeSlide.etag=Math.random();
		/*convert fasttime to date*/
		if(slide.dateStart != null){
			$scope.activeSlide.dateStart=new Date(slide.dateStart);
		}
		if(slide.dateEnd != null){
			$scope.activeSlide.dateEnd=new Date(slide.dateEnd);
		}
		
        /*$scope.activeSlide = angular.copy(slide);*/
        $scope.activeSlide.sitesFull = [];
        $scope.activeSlide.locales = [];

        angular.forEach($scope.sites,function(site){
            if(_.contains($scope.activeSlide.sites,site.id)){
                $scope.activeSlide.sitesFull.push(site);
            }
        });

        setLocales();

        // Get tags list
        //$scope.Tags.list = [];
        $scope.Tags.selected = [];
        devStackGenericService.list("portal/tag").then(function(listTags){
            if (TypeUtils.isEmpty(listTags)) return;
            $scope.Tags.list = listTags;
            $scope.SlideFilters.data.listTag = listTags;

            $scope.Tags.selected = [];
            listTags.forEach(function(tag){
                var  e = _.findWhere(slide.listTag, {name: tag.name});
                if (!TypeUtils.isEmpty(e))
                    $scope.Tags.selected.push(tag);
            });
        });
		
		//Get group list
		devStackGenericService.list("portal/slidersitegroup").then(function(listGroupSite){
            if (TypeUtils.isEmpty(listGroupSite)) return;			
            $scope.GroupSite.list = listGroupSite;			
        });
    }

    $scope.isActiveSlide = function(id){
        return $scope.activeSlide.id == id;
    }

    $scope.hasSlides = function(){
        return angular.isDefined($scope.slides) && $scope.slides.length > 0;
    }


    $scope.createEmptySlide = function(){
        var newSlide = {};
        newSlide.new = true;
        newSlide.id = Math.random();
        newSlide.label = "";
        newSlide.sitesFull = [];
        newSlide.sites = [];
        // If tags is filtered, and new slide is create, auto add tag selection
        if (!TypeUtils.isEmpty($scope.SlideFilters.tags)){
            newSlide.listTag = angular.copy($scope.SlideFilters.tags);
        }

        $scope.slides.push(newSlide);
        $scope.setActiveSlide(newSlide);

    }

    $scope.saveSlide = function(currentSlide){
        //Set tag
        currentSlide.listTag = $scope.Tags.selected;	
		
        if(currentSlide.new){
            $scope.createSlide(currentSlide);
        }else{
            $scope.updateSlide(currentSlide);
        }
		goToEdit();
    }


    $scope.createSlide = function(currentSlide){
        $scope.saveBtnIsDisabled=true;
        var newSlide = {};
        newSlide.sites = getSitesIds(currentSlide);
        newSlide.published = currentSlide.published;
        newSlide.label = currentSlide.label;
        newSlide.url = currentSlide.url;
        newSlide.id = "";
        newSlide.listTag = currentSlide.listTag;
		newSlide.dateStart = currentSlide.dateStart;
		newSlide.dateEnd = currentSlide.dateEnd;
		
        portalService.createSlide(newSlide).then(function(createdSlide) {			
            //Remove filter published
            delete $scope.SlideFilters.published;

            $scope.refreshSlide().then(function(slides) {
                $translate.refresh();
                $scope.slides = slides;
                var s = _.findWhere(slides,{id:createdSlide.id});
                $scope.setActiveSlide(s);
                $scope.saveBtnIsDisabled=false;
            })
        });
    }

    $scope.updateSlide = function(currentSlide){
        $scope.saveBtnIsDisabled=true;
        currentSlide.sites = getSitesIds(currentSlide);
        portalService.updateSlide(currentSlide).then(function(s){
	            $scope.refreshSlide().then(function(slides) {
                $translate.refresh();
                $scope.slides = slides;
                $scope.setActiveSlide(s);
                $scope.saveBtnIsDisabled=false;
                $scope.publishBtnIsDisabled =false;
            });
        });
    };

    $scope.deleteSlide = function(currentSlide){
        if(currentSlide.new){
            var i = $scope.slides.indexOf(currentSlide);
            $scope.slides.splice(i,1);
            if(angular.isDefined($scope.slides) && $scope.slides.length > 0){
                $scope.setActiveSlide($scope.slides[0]);
            }
        }else{
            portalService.deleteSlide(currentSlide.id).then(function(){
                $scope.refreshSlide().then(function (slides) {
                    $scope.slides = slides;
                    if (slides.length > 0) {
                        $scope.setActiveSlide(slides[0]);
                    }

                });
            });
        }
    }

    $scope.togglePublish = function(currentSlide){
        $scope.publishBtnIsDisabled =true;

        if(currentSlide.published){
            currentSlide.published = false;
        }else{
            currentSlide.published = true;
        }
        $scope.updateSlide(currentSlide);
		goToEdit();
    }

    /** Clone slide */
    $scope.cloneSlide = function(slide){
        if (slide.new == true)
            return;

        //Remove filter published
        delete $scope.SlideFilters.published;

        portalService.cloneSlide(slide).then(function(cloneSlide){
            $scope.refreshSlide().then(function(slides) {
                $translate.refresh();
                $scope.slides = slides;
                var s = _.findWhere(slides,{id:cloneSlide.id});
                $scope.setActiveSlide(s);
                $scope.saveBtnIsDisabled=false;
            })
        });
    }

    /** Tags */
    $scope.Tags = {
        newTagTransformer : function (value) {
            return {
                name: value
            }
        },

        selected : [],
        list: []
    };

	/** GroupSite */
    $scope.GroupSite = {
        list: []
    };

    $scope.SlideFilters = {
        data: {
            listTag : []
        },
        decorators: {
            tags: function(tag){ return tag.name; },
            published: function(published){
                if (published == true)
                    return $translate.instant("key.admin.published");
            }
        },
        tags: [],
        published: null
    }
    /**
     * Handle Settings panel
     * @type {{}}
     */
    $scope.Sortable = {
        /** Sortable options
         * @see http://api.jqueryui.com/sortable/ for options
         */
        sortableOptions: {
            //axis: "y",
            handle: '> .moveHandle',
            cursor: "row-resize",
            forcePlaceholderSize: true,
            placeholder: "placeholder",
            items: ".list-group-item:not(.not-orderable)",
            stop: function(e, ui) {
                var slideId = ui.item.attr("slideId");
                if (TypeUtils.isEmpty(slideId))
                    return;

                var afterId = "";
                for (let slide of $scope.slides) {
                    if (TypeUtils.isEmpty(slide.id)){
                        continue;
                    }
                    if(slide.id == slideId){
                        break;
                    }
                    afterId = slide.id;
                }
                if(afterId==""){
                   devStackGenericService.$post("portal/slides/:slideId/order", {}, {slideId: slideId});
                }else{
                   devStackGenericService.$post("portal/slides/:slideId/order/:afterSlideId/after", {}, {slideId: slideId, afterSlideId: afterId});
                }
            }
        },
    };

    // Initialize filters
    devStackGenericService.list("portal/tag").then(function(listTags) {
        $scope.SlideFilters.data.listTag = listTags;
    });

    devStackService.onDevStackEvent("Filter.slide.apply", function(e, filters){
        $scope.refreshSlide().then(function(slides) {
            $scope.slides = slides;
            if(slides.length > 0){
                $scope.setActiveSlide(slides[0]);
            }
        });
    });


    $scope.refreshSlide = function(){
        var criteria = criteriaService.createCriteria();

        // Tags
        if (!TypeUtils.isEmpty($scope.SlideFilters.tags)){
            criteria.add(SimpleCriterion.addIn("tagId", criteriaService.getCriterionValues($scope.SlideFilters.tags, "id")));
        }
        // published
        if ($scope.SlideFilters.published == true){
            criteria.add(SimpleCriterion.addEquals("published", true));
			var dateNow=new Date();
			criteria.add(
				LogicalCriterion.or(
					LogicalCriterion.and(SimpleCriterion.addIsNull("dateStart"), SimpleCriterion.addIsNull("dateEnd")),
					LogicalCriterion.or(
						LogicalCriterion.and(SimpleCriterion.addLessEquals("dateStart",dateNow), SimpleCriterion.addGreaterEquals("date_end",dateNow)),
						LogicalCriterion.or(
							LogicalCriterion.and(SimpleCriterion.addLessEquals("dateStart",dateNow), SimpleCriterion.addIsNull("dateEnd")),
							LogicalCriterion.and(SimpleCriterion.addIsNull("dateStart"), SimpleCriterion.addGreaterEquals("date_end",dateNow))
						)	
					)	
				)
			);
        }

        return portalService.getCarouselSlides(criteria);
    }

    $scope.refreshSlide().then(function(slides) {
        $scope.slides = slides;
        directoryService.sites().then(function (sites) {
            $scope.sites = sites;			
            if(slides.length >0){
                $scope.setActiveSlide(slides[0]);
            }

        });
    });

	$scope.onSelectGroup= function(item){		
		$scope.activeSlide.sitesSelected=[];
		if(item.sites.length > 0){
			item.sites.forEach(function(itemSite) {			  	
				$scope.activeSlide.sitesSelected.push(itemSite);				 
			});
		}
    }
	$scope.onAddGroup= function(){			
		if ($scope.activeSlide.sites === null){  
			$scope.activeSlide.sites = [];
		}
		if($scope.activeSlide.sitesSelected.length > 0){
			$scope.activeSlide.sitesSelected.forEach(function(item) {			  					
				if($scope.activeSlide.sites.indexOf(item) == -1){
			        $scope.activeSlide.sites.push(item);
			    }					 
			});
		}
		 angular.forEach($scope.sites,function(site){
            if(_.contains($scope.activeSlide.sites,site.id)){
				if($scope.activeSlide.sitesFull.indexOf(site) == -1){
			        $scope.activeSlide.sitesFull.push(site);
			    }                
            }
        });
    }
	$scope.onRemoveGroup= function(){				
		if($scope.activeSlide.sitesSelected.length > 0){
			$scope.activeSlide.sitesSelected.forEach(function(item) {	
		    	$scope.activeSlide.sites.splice($scope.activeSlide.sites.indexOf(item), 1);
			});
		}				
		if($scope.activeSlide.sitesSelected.length > 0){
			$scope.activeSlide.sitesSelected.forEach(function(item) {				
				$scope.activeSlide.sitesFull.forEach(function(itemFS, indexFS) {	
					if(itemFS.id==item){
						$scope.activeSlide.sitesFull.splice(indexFS, 1);
					}					
				});											 
			});
		}			 
    }
});

portalControllersModule.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            items.forEach(function(item) {
                var itemMatches = false;

                var keys = Object.keys(props);
                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});