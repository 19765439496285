import { portalDirectivesModule } from "./portal-directives-module";
portalDirectivesModule.directive('portalCarousel',function() {
    return {
        restrict : 'A',
        scope: true,
        link : function($scope) {
            $scope.$watch('slides',function(newValue,oldVAlue) {
                if (typeof newValue != 'undefined') {
                    $('#da-slider').cslider({
                        autoplay    : true,
                        interval    : 9000
                    });
                }
            });
        }
    }
});