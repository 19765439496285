import { portalModule } from "../app/portal-config";

portalModule.config(['$stateProvider', 'devStackServiceProvider', function ($stateProvider, devStackServiceProvider) {
    var backup = devStackServiceProvider.getRestRemoteConfig()['portalBackup'] == 'true';

    $stateProvider.state('portal', {
        url: "/",
        views: {
            'main': {
                templateUrl:function() {
                    if (backup) {
                        return 'views/portal/home/backup.html'
                    } else {
                        return 'views/portal/home/main.html'
                    }
                },
                controller: 'MainCtrl'
            }
        }
    })
        .state('admin', {
            url: "/admin",
            views: {
                'main': {
                    templateUrl: 'views/portal/admin/admin.html',
                    controller: 'AdminCtrl'
                }
            }/*,
            redirectionState : 'portal',
            securityExp : 'hasRole(devStackService.portalAdminRole())'*/
        })
		.state('admingroup', {
            url: "/admingroup",
            views: {
                'main': {
                    templateUrl: 'views/portal/admin/admingroup.html',
                    controller: 'AdminGroupCtrl'
                }
            }
        });
}]);
