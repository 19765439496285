import "./scripts/assets/app.js";
import "./scripts/assets/index.js";
import "./scripts/portal/app/portal-config";
import "./scripts/portal/app/portal-routes";
import "./scripts/portal/controllers/portal-controllers-module";
import "./scripts/portal/controllers/ctrl-admin";
import "./scripts/portal/controllers/ctrl-admin-group";
import "./scripts/portal/controllers/ctrl-carousel";
import "./scripts/portal/controllers/ctrl-countdown";
import "./scripts/portal/controllers/ctrl-main";
import "./scripts/portal/directives/portal-directives-module";
import "./scripts/portal/directives/drtv-carousel";
import "./scripts/portal/filters/portal-filters-module";
import "./scripts/portal/services/portal-services-module";
import "./scripts/portal/services/srvc-portal";
