'use strict';

/**
 * @ngdoc function
 * @name delfingenApp.controller:CarouselCtrl
 * @description
 * # CarouselCtrl
 * Controller of the delfingenApp
 */
import { portalControllersModule } from "../controllers/portal-controllers-module";
portalControllersModule.controller('CarouselCtrl', function ($scope, devStackService, $resource, $filter, securityService, portalService,$translate) {

    $scope.restAppBackendUrl =  devStackService.restAppBackendUrl();
    $scope.etag=Math.random();
    securityService.hasRole(devStackService.envSite() + '_portal_admin', function (pHasRoleAdminPortal) {
        $scope.hasRoleAdminPortal = pHasRoleAdminPortal
    });

    portalService.getCarouselPublishedSlides().then(function (sliders) {

        securityService.getConnectedUser().then(function (user) {
            $scope.slides = [];
            var siteSliders = $filter('filter')(sliders, function (slider) {
                return _.contains(slider.sites, user.site) || slider.sites === null;
            });

            for (var i = 0; i < siteSliders.length; i++) {
                $scope.slides = $scope.slides.concat(siteSliders[i]);
            }

            if ($scope.slides.length == 0) {
                $scope.slides = $filter('filter')(sliders, function (slider) {
                    return slider.sites[0] == "default";
                });
            }
        });
    });


    $.Slider.prototype.stop = function () {
        if (this.options.autoplay) {
            clearTimeout(this.slideshow);
            this.options.autoplay = false;
        }
    };

    $scope.isFrench = function() {
    	return ($translate.use() == 'fr');
    }

    $scope.videoShown = false;


    $scope.showVideo = function () {
        $('#da-slider').cslider("stop");
        $scope.videoShown = true;
        $scope.youtubeUrl = '//www.youtube.com/embed/VPmE0jRgzqE?autoplay=1';
    };
});


