'use strict';

import { portalControllersModule } from "../controllers/portal-controllers-module";

portalControllersModule.controller('CountdownCtrl', function ($scope,$interval) {
	
	function pad(n) {
		return (n < 10 ? '0' : '') + n;
	}
	
	var target_date = new Date(2017, 3, 18, 12, 0, 0, 0).getTime();
	var days=0;
	var hours=0;
	var minutes=0;
	var seconds=0;
	
	var getCountdown = function() {
		var current_date = new Date().getTime();
		var seconds_left = (target_date - current_date) / 1000;
		days = pad(parseInt(seconds_left / 86400) );
		seconds_left = seconds_left % 86400;
		hours = pad(parseInt(seconds_left / 3600) );
		seconds_left = seconds_left % 3600;
		minutes = pad(parseInt(seconds_left / 60));
		seconds = pad(parseInt( seconds_left % 60 ));
	}
	
	 getCountdown();
	
	
	$scope.getDays = function() {
		return days;
	}
	
    $scope.getHours = function() {
		return hours;
	}

    $scope.getMinutes = function() {
    	return minutes;
    }
    
    $scope.getSeconds = function() {
    	return seconds;
    }
	
	$interval(getCountdown, 1000);
	
	
//	var target_date = new Date().getTime() + (1000*3600*48); // set the countdown date
//	var days, hours, minutes, seconds; // variables for time units
//
//	var countdown = document.getElementById("tiles"); // get tag element
//
//	getCountdown();
//
//	setInterval(function () { getCountdown(); }, 1000);
//
//	function getCountdown(){
//
//		// find the amount of "seconds" between now and target
//		var current_date = new Date().getTime();
//		var seconds_left = (target_date - current_date) / 1000;
//
//		days = pad( parseInt(seconds_left / 86400) );
//		seconds_left = seconds_left % 86400;
//			 
//		hours = pad( parseInt(seconds_left / 3600) );
//		seconds_left = seconds_left % 3600;
//			  
//		minutes = pad( parseInt(seconds_left / 60) );
//		seconds = pad( parseInt( seconds_left % 60 ) );
//
//		// format countdown string + set tag value
//		countdown.innerHTML = "<span>" + days + "</span><span>" + hours + "</span><span>" + minutes + "</span><span>" + seconds + "</span>"; 
//	}
	
});