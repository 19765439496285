'use strict';

import { portalControllersModule } from "../controllers/portal-controllers-module";
import { TypeUtils } from "../../../../node_modules/dev-stack-ui-bootstrap/src/classes/utils/TypeUtils";
portalControllersModule.controller('AdminGroupCtrl', function ($scope,devStackService,
                                                         directoryService,portalService,devStackGenericService)
{
	$scope.restAppBackendUrl =  devStackService.restAppBackendUrl();
	$scope.activeGroupSite= {};
	//Get group list
	devStackGenericService.list("portal/slidersitegroup").then(function(listGroupSite){
        if (TypeUtils.isEmpty(listGroupSite)) return;			
        $scope.GroupSite.list = listGroupSite;
		$scope.setEditGroup(listGroupSite[0]);
    });
	/** GroupSite */
    $scope.GroupSite = {
        list: []
    };
	directoryService.sites().then(function (sites) {
        $scope.sites = sites;	
    });
 	
	$scope.setEditGroup = function (groupsite) {		
        $scope.activeGroupSite = groupsite;		
    }
	$scope.checkChange=function(val){	
		if($scope.activeGroupSite.sites.indexOf(val.site.id) == -1){
	        $scope.activeGroupSite.sites.push(val.site.id);
	    }else{
			const index = $scope.activeGroupSite.sites.indexOf(val.site.id);
			if (index > -1) {
			  $scope.activeGroupSite.sites.splice(index, 1);
			}		
		}
	}
	 $scope.isActiveGroup = function(id){
        return $scope.activeGroupSite.id == id;
    }
	$scope.saveGroup = function(currentGroupSite){          
 		if(currentGroupSite.new){
            $scope.createGroupSite(currentGroupSite);
        }else{
            $scope.updateGroupSite(currentGroupSite);
        }
    }
	$scope.createGroupSite = function(currentGroupSite){		
		if (!TypeUtils.isEmpty(currentGroupSite.sites)){
			portalService.createSlideGroup(currentGroupSite).then(function(){
			$scope.activeGroupSite.new=false;
			refreshGroup(true);
		});
		}else{
			alert('No site selected');
		}
    }
	$scope.updateGroupSite = function(currentGroupSite){		
			if (!TypeUtils.isEmpty(currentGroupSite.sites)){
				portalService.updateSlideGroup(currentGroupSite);
			}else{
				alert('No site selected');
			}
        
    }
	$scope.createEmptyGroup = function(){
        var newGroup = {};
        newGroup.new = true;
		newGroup.id =Math.random();
        newGroup.label = "";
		newGroup.sites = [];
		newGroup.GroupSite=$scope.GroupSite;
      	$scope.setEditGroup(newGroup);

    }
	 $scope.deleteGroup = function(currentGroupSite){
		if(confirm('Delete confirm ?')){
			portalService.deleteSlideGroup(currentGroupSite).then(function(){
			refreshGroup(false);
        });
		}        
    }
	function refreshGroup(last){
		devStackGenericService.list("portal/slidersitegroup").then(function(listGroupSite){
        if (TypeUtils.isEmpty(listGroupSite)){
			 $scope.GroupSite.list=[];	
		}else{
			$scope.GroupSite.list = listGroupSite;
			if(last){
				var lastitem;
				listGroupSite.forEach(function(item){
					lastitem=item;
				});
				$scope.setEditGroup(lastitem);
			}else{
				$scope.setEditGroup(listGroupSite[0]);
			}
					
		}		
       return true;
    });
	}
});