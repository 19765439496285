'use strict';
import { SimpleCriterion } from "node_modules/dev-stack-ui-bootstrap/src/classes/criteria/SimpleCriterion";
import { TypeUtils } from "node_modules/dev-stack-ui-bootstrap/src/classes/utils/TypeUtils";
import { LogicalCriterion } from "node_modules/dev-stack-ui-bootstrap/src/classes/criteria/LogicalCriterion";
/**
 * @ngdoc service
 * @name delfingenApp.menu
 * @description
 * # menu
 * Service in the delfingenApp.
 */
import { portalServicesModule } from "./portal-services-module";

portalServicesModule.service('portalService', function PortalService($resource, devStackService, criteriaService, devStackGenericService) {

    return {
        getCarouselPublishedSlides : function(){
			var dateNow=new Date();						
            var criteria = criteriaService.createCriteria();
            criteria.add(SimpleCriterion.addEquals("published", true));
			criteria.add(
				LogicalCriterion.or(
					LogicalCriterion.and(SimpleCriterion.addIsNull("dateStart"), SimpleCriterion.addIsNull("dateEnd")),
					LogicalCriterion.or(
						LogicalCriterion.and(SimpleCriterion.addLessEquals("dateStart",dateNow), SimpleCriterion.addGreaterEquals("date_end",dateNow)),
						LogicalCriterion.or(
							LogicalCriterion.and(SimpleCriterion.addLessEquals("dateStart",dateNow), SimpleCriterion.addIsNull("dateEnd")),
							LogicalCriterion.and(SimpleCriterion.addIsNull("dateStart"), SimpleCriterion.addGreaterEquals("date_end",dateNow))
						)	
					)	
				)
			);
            return devStackGenericService.list("portal/slides", {}, criteria, 0, -1);
			//return returns;
        },
        getCarouselSlides : function(criteria) {
            if (TypeUtils.isEmpty(criteria))
                criteria = criteriaService.createCriteria();

            return devStackGenericService.list("portal/slides", {}, criteria, 0, -1);
        },

        createSlide : function(slide){
            return devStackGenericService.create("portal/slides", slide);
        },
        updateSlide : function(slide){
            return devStackGenericService.update("portal/slides", slide, {id: slide.id});
        },
        deleteSlide : function(id){
            return devStackGenericService.delete("portal/slides", id);
        },
        cloneSlide : function(slide){
            return devStackGenericService.$post("portal/slides/:id/clone", {}, {id : slide.id});
        },
		createSlideGroup : function(groupSite){
            return devStackGenericService.create("portal/slidersitegroup", groupSite);
        },
		updateSlideGroup : function(groupSite){
            return devStackGenericService.update("portal/slidersitegroup", groupSite, {id: groupSite.id});
        },
 		deleteSlideGroup : function(id){
            return devStackGenericService.delete("portal/slidersitegroup", id);
        },
    }
});